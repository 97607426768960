import Alpine from "alpinejs";
import collapse from "@alpinejs/collapse";
import { animatedCounter } from "./animations.js";

window.animatedCounter = animatedCounter;

window.Alpine = Alpine;  
Alpine.plugin(collapse);
Alpine.start();
 
import 'phosphor-icons';
import "./fancybox.js";
import "./alpine.js";
import "./swiper.js";
import "./animations.js";