import { Fancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox.css";


document.addEventListener("DOMContentLoaded", () => {

    // Documentation: https://fancyapps.com/docs/ui/fancybox/options
    Fancybox.bind("[data-fancybox]", {
        infinite: false,
        on: {
            beforeClose: (fancybox, slide) => {
                const video = slide.$content.querySelector("video");

                if (video) {
                    video.pause();
                }
            }
        },
        Toolbar: {
            display: [
                { id: "counter", position: "left" },
                "zoom",
                "fullscreen",
                "thumbs",
                "close",
            ],
        },
    });



});