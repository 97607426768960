import Swiper from 'swiper/bundle';
import 'swiper/swiper-bundle.css';
document.addEventListener("DOMContentLoaded", function () {
    const swiper = new Swiper(".process", {
        slidesPerView: 1.25,
        watchSlidesProgress: true,
        spaceBetween: 0,
        breakpoints: {
            640: { slidesPerView: 2 },
            768: { slidesPerView: 2 },
            1024: { slidesPerView: 3 },
            1280: { slidesPerView: 4 },
        },
        navigation: {
            nextEl: '.ph-caret-right',
            prevEl: '.ph-caret-left',
        },
    });
});
document.addEventListener("DOMContentLoaded", function () {
    const swiper = new Swiper(".netflix-slider", {
        slidesPerView: 1.25,
        watchSlidesProgress: true,
        spaceBetween: 16,
        breakpoints: {
            768: { slidesPerView: 2 },
            1280: { slidesPerView: 3 },
        },
        navigation: {
            nextEl: '.ph-caret-right',
            prevEl: '.ph-caret-left',
        },
    });
});
document.addEventListener("DOMContentLoaded", function () {
    const swiper = new Swiper(".quotes", {
        slidesPerView: 1,
        loop: true,
        autoplay: { // Add autoplay settings
            delay: 5000,
            disableOnInteraction: false,
            pauseOnMouseEnter: true,
        }, 
        pagination: {
            el: '.swiper-pagination',
            clickable: true,
        },
    });
});
