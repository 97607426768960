import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

(function () {
    const animatedElements = gsap.utils.toArray('[data-animate]');

    animatedElements.forEach((el) => {
        const animation = el.getAttribute('data-animate');
        const delay = parseInt(el.getAttribute('data-delay')) || 0;

        if (animation) {
            gsap.from(el, {
                opacity: 0,
                ...getAnimationProperties(animation),
                duration: 0.4, // Increased duration for smoother animations
                delay: delay / 1000, // Delay in seconds
                ease: "power2.out", // Add easing for smoothness
                scrollTrigger: {
                    trigger: el,
                    start: "top 95%", // Starts a bit earlier when 80% of the viewport is in view
                    toggleActions: "play none none none",
                    once: true, // Ensures animation only happens once
                },
            });
        }
    });

    function getAnimationProperties(animation) {
        const animations = {
            "fade-up": { y: 40 },
            "fade-down": { y: -40 },
            "fade-right": { x: -40 },
            "fade-left": { x: 40 },
            "fade": {},
        };
        return animations[animation] || {};
    }
})();
  
export function animatedCounter(target, time = 2000, start = 0, isCountDown = false) {
    return {
        current: start,
        target: 0,
        time: time,
        animation: null,
        init() {
            if (typeof (target) === "string") {
                this.target = this.$data[target]
            } else {
                this.target = target
            }

            const element = this.$el;

            ScrollTrigger.create({
                trigger: element,
                start: "top bottom",
                onEnter: () => this.updateCounter(),
                onEnterBack: () => this.updateCounter(),
                once: true,
            });

            if (ScrollTrigger.isInViewport(element)) {
                this.updateCounter();
            }
        },
        updateCounter() {
            if (typeof (target) === "string") {
                this.target = this.$data[target]
            }

            const element = this.$el;
            const counterSpan = element.querySelector('.counter-span');

            gsap.to(counterSpan, { opacity: 1 });

            if (this.animation) {
                this.animation.kill();
            }

            // Set the starting point for countdown
            const duration = this.time / 1000;

            this.animation = gsap.to(this, {
                current: this.target,
                duration: duration,
                onUpdate: () => {
                    if (counterSpan) {
                        counterSpan.innerText = this.current.toFixed(2).toLocaleString('da-DK');
                    }
                },
                onComplete: () => {
                    if (counterSpan) {
                        counterSpan.innerText = this.target.toFixed(2).toLocaleString('da-DK');
                    }
                }
            });
        }
    };
}
